import React from "react"
import { graphql, StaticQuery } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"
// import Bio from "../components/bio"
import PostCard from "../components/postCard"

//CSS imports
import "../utils/normalize.css"
import "../utils/css/screen.css"

//TODO: switch to staticQuery, get rid of comments, remove unnecessary components, export as draft template
const GalleryIndex = ({ data }, location) => {
  const siteTitle = data.site.siteMetadata.title
  const cards = data.allMarkdownRemark.edges
  let cardCounter = 0

  console.log("Data = ", data)

  return (
    <Layout title={siteTitle}>
      <SEO
        title="Karam Photography"
        keywords={[`photography`, `photos`, `gatsby`, `javascript`, `react`]}
      />
      {/* {data.site.siteMetadata.description && (
        <header className="page-head">
          <h2 className="page-head-title">
            {data.site.siteMetadata.description}
          </h2>
        </header>
      )} */}
      <figure className="kg-card kg-image-card">
        <Img fluid={data.smallPic.childImageSharp.fluid} className="kg-image" />
      </figure>
      <div className="post-feed">
        {cards.map(({ node }) => {
          cardCounter++
          return (
            <PostCard
              key={node.fields.slug}
              count={cardCounter}
              node={node}
              postClass={`post`}
            />
          )
        })}
      </div>
    </Layout>
  )
}

const indexQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
    smallPic: file(relativePath: { eq: "karam_dark.png" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM D, YYYY")
            title
            description
            tags
            thumbnail {
              childImageSharp {
                fluid(maxWidth: 1360) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`

export default props => (
  <StaticQuery
    query={indexQuery}
    render={data => (
      <GalleryIndex location={props.location} props data={data} {...props} />
    )}
  />
)
